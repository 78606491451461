import React from 'react';
import * as constants from '../constants';
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import Moment from 'react-moment';
import stripHtml from "string-strip-html";
const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

export default function NewsFeedItem({item}) {
  return (
    <div className="post-wrapper wow fadeIn" data-wow-duration="2s">
      <div className="post-thumb img-zoom-in">
        <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}>
          <img className="img-custom-entry-thumb" src={item._embedded['wp:featuredmedia'] ? item._embedded['wp:featuredmedia']['0'].source_url : 'images/slider/slide-02.jpg'} alt={item._embedded['wp:featuredmedia'] ? item._embedded['wp:featuredmedia']['0'].title.rendered : 'image'} />
        </Link>
      </div>
      <div className="post-info">
        <span className="color-2">{item ? constants.data.category[item.categories[0]] : ''}</span>
        {/* <h3 className="post-title"><Link to="/">{entities.decode(item.title.rendered)} </Link></h3> */}
        {/* <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`} className="post-title" style={{color: "white"}}>
          <Truncate lines={1} trimWhitespace={true}>
            {stripHtml(item.title.rendered)}
          </Truncate>
        </Link> */}
        <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`} className="post-title line-clamp-two" style={{color: "white"}}>
          {stripHtml(item.title.rendered)}
        </Link>
        <div className="post-editor-date">
          <div className="post-date">
            <i className="pe-7s-clock"></i> <Moment format="DD/MM/YYYY">{item.date}</Moment>
            </div>
          <div className="post-author-comment"><i className="pe-7s-comment"></i> {item._embedded.replies ? item._embedded.replies[0].length : 0} </div>
          <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`} className="readmore pull-right"><i className="pe-7s-angle-right"></i></Link>
        </div>
      </div>
    </div>
  )
}
import React from 'react';
import NewsFeedItem from './NewsFeedItem';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';

const options = {
  items: 1,
  //nav: true,
  rewind: true,
  autoplay: true
};

const size = 3;

export default function NewsFeed({posts, stickyPosts, news, projects, documents}) {
  return (
    <section className="news-feed">
      <div className="container">
        <div className="row row-margin">
          <div className="col-sm-3 hidden-xs col-padding">
            {posts.length > 0 &&
              <NewsFeedItem key={projects[0].id} item={projects[0]} />
            }
          </div>
          <div className="col-xs-12 col-md-6 col-sm-6 col-padding">
            {posts.length > 0 &&
              <OwlCarousel options={options}>
                {news.slice(0, size).map(post => {
                  return (
                    <div key={post.id} className="item">
                      <NewsFeedItem item={post} />
                    </div>
                  )
                })}
              </OwlCarousel>
            }
          </div>
          <div className="col-sm-3 hidden-xs col-padding">
            {posts.length > 0 &&
              <NewsFeedItem key={documents[0].id} item={documents[0]} />
            }
          </div>
        </div>
      </div>
    </section>
  )
}

const searchCategory = (id, posts) => {
  return posts.find(post => post.categories.includes(id))
}
import React from 'react';
import { getLastComments } from '../api';
import SingleComment from '../components/SingleComment';

export default class NewsBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: []
    }
  }

  async componentDidMount() {
		try {
			const comments = await getLastComments()
			this.setState({ comments })
		} catch (error) {
			console.log('Error last comments', error);
		}
	}

  render() {
    return (
      <div className="latest-comments-inner">
        <h3 className="category-headding ">POSLEDNJI KOMENTARI</h3>
        <div className="headding-border"></div>
        {this.state.comments.length > 0 &&
          this.state.comments.slice(0,3).map(comment => {
            return (<SingleComment item={comment} key={comment.id} />)
          })
        }
      </div>
    )
  }
}
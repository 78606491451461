import React from 'react';
import * as constants from '../constants';
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import stripHtml from "string-strip-html";
import TextTruncate from 'react-text-truncate';
const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

export default function ListingItem({item}) {
  return (
		<div className="post-style2 wow fadeIn" data-wow-duration="1s">
			<Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}><img width="250px" height="205px" src={(item._embedded['wp:featuredmedia']) ? item._embedded['wp:featuredmedia']['0'].source_url : 'images/slider/slide-02.jpg'} alt={item._embedded['wp:featuredmedia'] ? item._embedded['wp:featuredmedia']['0'].title.rendered : 'image'} /></Link>
			<div className="post-style2-detail w-100">
				{/* <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}>
					<TextTruncate
						line={2}
						element="h3"
						truncateText="…"
						text={stripHtml(item.title.rendered)}
					/>
				</Link> */}
				<Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}>
          <h3 className="line-clamp-two">
            {stripHtml(item.title.rendered)}
          </h3>
        </Link>
				<div className="date">
					<ul>
						<li><img src="/images/user.png" className="img-responsive" alt="user"/></li>
						<li>Autor <span className="custom-comments">{item._embedded.author ? item._embedded.author[0].name : ''}</span> --</li>
						<li><Moment format="DD/MM/YYYY">{item.date ? item.date : ''}</Moment> --</li>
						<li><span className="custom-comments">Komentari ({item._embedded.replies ? item._embedded.replies[0].length : 0})</span></li>
					</ul>
				</div>
				{/* <TextTruncate
					line={3}
					element="p"
					truncateText="…"
					text={stripHtml(item.content.rendered)}
				/> */}
				<p className="line-clamp-three">
					{stripHtml(item.content.rendered)}
				</p>
				<Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}><span className="btn btn-style">Pročitaj više</span></Link>
			</div>
		</div>
  )
}
import React from 'react';
import { createComment } from '../api';
import * as constants from '../constants';
import Moment from 'react-moment';
import stripHtml from "string-strip-html";
const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

export default class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      name: '',
			email: '',
      message: '',
      commentMessage: '',
    }
  }

  handleOnChange = (inputName, event) => this.setState({ [inputName]: event.target.value});
  
  changeShow = () => {
    this.setState({ show: !this.state.show, name: '', email: '', message: '' });
  }

  submit = async () => {
    try {
      const reply = await createComment(this.state.name, this.state.email, this.state.message, this.props.postId, this.props.item.id);
      this.setState({ name: '', email: '', message: '', show: false, commentMessage: constants.successComment  })
    } catch (error) {
			if (+error.response.status === 409) {
				this.setState({ commentMessage: constants.duplicateComment });
			}
    }
	}

  render() {
    const emailChekc = this.state.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    return(
      <>
        <div className="comment-avatar"><img src="/images/user.png" className="img-circle" alt="user"/></div>
        <div className="comment-box">
          <div className="comment-head">
            <h6 className="comment-name"><a>{this.props.item.author_name}</a></h6>
            <span><Moment format='Do MMMM YYYY, h:mm:ss a'>{this.props.item.date}</Moment></span>
            {this.props.reply ? <i onClick={this.changeShow} className="fa fa-reply"></i> : ''}
          </div>
          <div className="comment-content">
            {stripHtml(this.props.item.content.rendered)}
          </div>
          {this.state.commentMessage ? <p>{this.state.commentMessage}</p> : ''}
          {this.state.show && 
            <form>
              <div className="row mt-1">
                <div className="col-sm-6">
                  <span className={"input " + (this.state.name !== '' ? "input--filled" : '')}>
                    <input 
                      className="input_field" 
                      type="text" 
                      onChange={this.handleOnChange.bind(this, 'name')}
                      value={this.state.name} />
                    <label className="input_label" htmlFor="input-1">
                      <span className="input_label_content" data-content="Your Name">Vaše ime</span>
                    </label>
                  </span>
                </div>
                <div className="col-sm-6">
                  <span className={"input " + (this.state.email !== '' ? "input--filled" : '')}>
                    <input 
                      className="input_field" 
                      type="email" 
                      onChange={this.handleOnChange.bind(this, 'email')}
                      value={this.state.email}/>
                    <label className="input_label" htmlFor="input-2">
                      <span className="input_label_content" data-content="Your Email">Vaš email</span>
                    </label>
                  </span>
                </div>
                <div className="col-sm-12">
                  <span className={"input " + (this.state.message !== '' ? "input--filled" : '')}>
                    <textarea 
                      className="input_field" 
                      onChange={this.handleOnChange.bind(this, 'message')}
                      value={this.state.message}>
                    </textarea>
                    <label className="input_label" htmlFor="message">
                        <span className="input_label_content" data-content="Your Message">Vaša poruka</span>
                    </label>
                  </span>
                  <button onClick={this.submit} disabled={!this.state.name || !emailChekc || !this.state.message}  type="button" className="btn btn-style no-outline">Postavite Vaš komentar</button>
                </div>
              </div>
            </form>
          }
        </div>
      </>
    )
  }
}
import React from 'react';
import * as constants from '../constants';
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import stripHtml from "string-strip-html";
import TextTruncate from 'react-text-truncate';
const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

export default function NewsBoxItem({item}) {
	return (
    <div className="box-item wow fadeIn" data-wow-duration="1s">
      <div className="img-thumb">
        <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}>
          <img className="entry-thumb" src={item._embedded['wp:featuredmedia'] ? item._embedded['wp:featuredmedia']['0'].source_url : '/images/slider/slide-02.jpg'} alt={item._embedded['wp:featuredmedia'] ? item._embedded['wp:featuredmedia']['0'].title.rendered : 'image'} height="80" width="90" />
        </Link>
      </div>
      <div className="item-details d-grid">
        <div>
          <div>
            <h6 className={`sub-category-title bg-color-${item.categories[0] === constants.categoryNewsNumber ? 5 : item.categories[0] === constants.categoryProjectsNumber ? 3 : 1 }`}>
            {item ? constants.data.category[item.categories[0]] : ''}
            </h6>
          </div>
          <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}>
            <h3 className="line-clamp-two td-module-title">
              {stripHtml(item.title.rendered)}
            </h3>
          </Link>
          {/* <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`} style={{ textDecoration: 'none' }}>
            <h3 className="td-module-title">{item ? stripHtml(item.title.rendered) : ''}</h3>
          </Link> */}
        </div>
        <div className="post-editor-date align-self">
          <div className="post-date">
            <i className="pe-7s-clock"></i> <Moment format="DD/MM/YYYY">{item.date}</Moment>
          </div>
          <div className="post-author-comment"><i className="pe-7s-comment"></i> {item._embedded.replies ? item._embedded.replies[0].length : 0} </div>
        </div>
      </div>
    </div>
	);
}
import React from 'react';
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router' // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './configureStore'
import HomePage from './containers/HomePage';
import NewsPage from './containers/NewsPage';
import DocumentsPage from './containers/DocumentsPage';
import ProjectPage from './containers/ProjectPage';
import PostsPage from './containers/PostsPage';
import Header from './components/Header';
import Footer from './components/Footer';
import InProgressPage from './containers/InProgressPage';
import AboutUsPage from './containers/AboutUsPage';
import SingleDocumentPage from './containers/SingleDocumentPage';
import NotFound from './containers/NotFound';
import MediaShelterPage from './containers/MediaShelterPage';
import './App.css';

const store = configureStore({})

export default function App() {
  
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Header />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/projekti/:slug" component={PostsPage} />
          <Route path="/vesti/:slug" component={PostsPage} />
          <Route path="/dokumenta/:slug" component={SingleDocumentPage} />
          <Route path="/medijsko-skloniste/:slug" component={PostsPage} />
          <Route path="/dokumenta" component={DocumentsPage} />
          <Route path="/projekti" component={ProjectPage} />
          <Route path="/vesti" component={NewsPage} />
          <Route path="/galerija" component={InProgressPage} />
          <Route path="/o-nama" component={AboutUsPage} />
          <Route path="/medijsko-skloniste" component={MediaShelterPage} />
          <Route path="*" component={NotFound} />
        </Switch>
        <Footer />
      </ConnectedRouter>
    </Provider>
  );
}

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import NewsFeed from './../components/NewsFeed';
import HomeContent from './../components/HomeContent';
import HomeBottom from './../components/HomeBottom';
import { getPosts, getCategory } from './../api';

export default class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      stickyPosts: [],
      projects: [],
      documents: [],
      news: [],
      showSpinner: true,
    }
  }

  async componentDidMount() {
    const stickyPosts = await getPosts(true);
    const posts = await getPosts();
    const news = await getCategory(50);
    const projects = await getCategory(51);
    const documents = await getCategory(52);
    this.setState({ posts: posts, stickyPosts: stickyPosts, news, projects, documents, showSpinner: false });
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Šumadijski centar za građanski aktivizam "RES PUBLICA" - Šumadijski centar za građanski aktivizam</title>
          <meta name="title" content='Šumadijski centar za građanski aktivizam "RES PUBLICA" - Šumadijski centar za građanski aktivizam' />
          <meta name="description" content="Šumadijski centar za građanski aktivizam" />
					<meta name="keywords" content="Vesti, Najnovije vesti, RES PUBLICA, Projekti, Dokumenta" />
          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          {/* <meta property="og:url" content="https://metatags.io/" /> */}
          <meta property="og:title" content='Šumadijski centar za građanski aktivizam "RES PUBLICA" - Šumadijski centar za građanski aktivizam' />
          <meta property="og:description" content="Šumadijski centar za građanski aktivizam" />
          {/* <meta property="og:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png" /> */}

          {/* Twitter */}
          <meta property="twitter:card" content="summary_large_image" />
          {/* <meta property="twitter:url" content="https://metatags.io/" /> */}
          <meta property="twitter:title" content='Šumadijski centar za građanski aktivizam "RES PUBLICA" - Šumadijski centar za građanski aktivizam' />
          <meta property="twitter:description" content="Šumadijski centar za građanski aktivizam" />
          {/* <meta property="twitter:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png" /> */}
        </Helmet>
        {this.state.showSpinner ? <div className="se-pre-con"></div> : ''}
        <NewsFeed posts={this.state.posts} stickyPosts={this.state.stickyPosts} news={this.state.news} projects={this.state.projects} documents={this.state.documents} />
        <HomeContent news={this.state.news} projects={this.state.projects} />
        <HomeBottom documents={this.state.documents} />
      </Fragment>
    );
  }
}


import React from 'react';
import OwlCarousel from "react-owl-carousel2";
import ArticlesItem from "./ArticlesItem";

const options = {
  items: 1,
  responsive:{
    479:{
      items:1,
      nav:true
    },
    768:{
      items:1,
      nav:true
    },
    980:{
      items:1,
      nav:true
    },
    1199:{
      items:1,
      nav:true
    }
  },
  pagination: false,
  dots: false,
  lazyLoad: true,
  nav: true,
  navText: [
    "<i class='fa pe-7s-angle-left'></i>",
    "<i class='fa pe-7s-angle-right'></i>"
  ],
  //navElement: 'category-headding'
};

const size = 6;

const items = 1;

export default function Articles({documents}) {
  const dummyArray = [...Array(items).keys()];
  return (
    <section className="articale-inner">
      <h3 className="category-headding ">POSLEDNJI DOKUMENTI</h3>
      <div className="headding-border"></div>
      <div className="row">
        {documents.length > 0 &&
        <OwlCarousel options={options} id="content-slide">
          {dummyArray.map((item, index) => {
            return (
              <div key={item} className="item">
                <div className="row rn_block d-flex flex-wrap">
                  {documents.map((post, i)=> {
                    if (i === i % size + index * size) {
                      return (
                        <ArticlesItem key={post.id} item={post} />
                      )
                    }
                  })}
                </div>
              </div>
            )
          })}
        </OwlCarousel>
        }
      </div>
    </section>
  )
}
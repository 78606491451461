import React from 'react';

const iframe = '<iframe height="100%" width="100%" scrolling="no" src="https://www.youtube.com/embed/duzNMSYB-Pw?start=317" frameborder="no" allowfullscreen></iframe>';

export default function VideoPlayer(props) {
  return (
    <aside>
      <div className="video-headding">VIDEO MATERIJAL</div>
      <div id="rypp-demo-4" className="RYPP r16-9" data-playlist="PLw8TejMbmHM6IegrJ4iECWNoFuG7RiCV_">
        <div className="RYPP-video">
          <Iframe iframe={iframe}/>
          <div className="RYPP-video-player">
          </div>
        </div>
        <div className="RYPP-playlist">
          <header>
            <h2 className="_h1 RYPP-title">Tiho nestajanje Kragujevačkih medija</h2>
            {/* <p className="RYPP-desc">Playlist subtitle <a href="#" target="_blank">#hashtag</a></p> */}
          </header>
          <div className="RYPP-items"></div>
        </div>
      </div>
    </aside>
  )
}

function Iframe(props) {
  return (<div className="iframe"  dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
}


import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router'
import ListingItem from '../components/ListingItem';
import NewsHeadingItem from '../components/NewsHeadingItem';
import { getCategoryPagination, getPosts } from '../api';
import NewsBox from '../components/NewsBox';
import CommentsLast from '../components/CommentsLast';
import SearchBox from '../components/SearchBox';
import AdBanner from '../components/AdBanner';
import VideoPlayer from '../components/VideoPlayer';
import * as constants from '../constants';

const size = 2;

class DocumentsPage extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
			page: 1,
			per_page: 8,
			researches: [],
			posts: [],
			total_pages: '',
			showSpinner: true,
    }
	}
	
	async componentDidMount() {
		let pageNumber = +window.location.href.split('=')[1] || 1;
		try {
			const researches = await getCategoryPagination(constants.categoryDocumentsNumber, (pageNumber - 1) * this.state.per_page, this.state.per_page);
			if (researches.data.length < 1 || pageNumber < 1) {
				pageNumber = 1;
				this.redirectTo('/dokumenta?strana=1');
			}
			const posts = await getPosts();
			this.setState({ researches: researches.data, posts, total_pages: researches.headers["x-wp-totalpages"], page: pageNumber, showSpinner: false })
			window.scrollTo(0, 0);
		} catch (error) {
			this.redirectTo('/');
		}
	}

	async componentWillReceiveProps(nextProps){
		if (nextProps.location.search !== this.props.location.search) {
			this.setState({ showSpinner: true })
			let pageNumber = +nextProps.location.search.split('=')[1] || 1;
			try {
				window.scrollTo(0, 0);
				const researches = await getCategoryPagination(constants.categoryDocumentsNumber, (pageNumber - 1) * this.state.per_page, this.state.per_page);
				if (researches.data.length < 1 || pageNumber < 1) {
					pageNumber = 1;
					this.redirectTo('/dokumenta?strana=1');
				}
				const posts = await getPosts();
				this.setState({ researches: researches.data, posts, total_pages: researches.headers["x-wp-totalpages"], page: pageNumber, showSpinner: false })
			} catch (error) {
				this.redirectTo('/');
			}
		}
  }

	pagination = pageNumber => {
		this.setState({ page: this.state.page + pageNumber, showSpinner: true }, async () => {
			const researches = await getCategoryPagination(constants.categoryDocumentsNumber, (this.state.page - 1) * this.state.per_page, this.state.per_page);
			this.setState({ researches: researches.data, total_pages: researches.headers["x-wp-totalpages"] });
    	this.props.history.push("/dokumenta?strana=" + this.state.page);
		})
	}
	
	redirectTo = route => this.props.history.push(route);

	render() {
		return (
			<Fragment>
				<Helmet>
					<title>DOKUMENTA - Šumadijski centar za građanski aktivizam "RES PUBLICA"</title>
          <meta name="title" content='DOKUMENTA - Šumadijski centar za građanski aktivizam "RES PUBLICA"' />
          <meta name="description" content="Pratite sva naša dokumenta na RES PUBLICA portalu. Budite u toku sa svim aktuelnim dešavanjima." />
					<meta name="keywords" content="Dokumenti, RES PUBLICA" />
          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          {/* <meta property="og:url" content="https://metatags.io/" /> */}
          <meta property="og:title" content='DOKUMENTA - Šumadijski centar za građanski aktivizam "RES PUBLICA"' />
          <meta property="og:description" content="Pratite sva naša dokumenta na RES PUBLICA portalu. Budite u toku sa svim aktuelnim dešavanjima." />
          {/* <meta property="og:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png" /> */}

          {/* Twitter */}
          <meta property="twitter:card" content="summary_large_image" />
          {/* <meta property="twitter:url" content="https://metatags.io/" /> */}
          <meta property="twitter:title" content='DOKUMENTA - Šumadijski centar za građanski aktivizam "RES PUBLICA"' />
          <meta property="twitter:description" content="Pratite sva naša dokumenta na RES PUBLICA portalu. Budite u toku sa svim aktuelnim dešavanjima." />
          {/* <meta property="twitter:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png" /> */}
				</Helmet>
				{this.state.showSpinner ? <div className="se-pre-con"></div> : ''}
				<section className="news-feed">
					<div className="container">
						<div className="row row-margin">
							{this.state.posts.slice(0,size).map(item => {
								return (
									<div className="col-xs-12 col-md-6 col-sm-6 col-padding" key={item.id}>
										<NewsHeadingItem item={item} />
									</div>
								)
							})}
						</div>
					</div>
				</section>
				<section className="article-post-inner">
					<div className="container">
						<div className="row">
							<div className="col-sm-8">
								<div className="articale-list">
									<h3 className="category-headding">Dokumenta</h3>
									<div className="headding-border"></div>
										{this.state.researches.map(item => {
											return (
												<div key={item.id}>
													<ListingItem item={item} />
												</div>
											)
										})}
								</div>
								<div>
									{this.state.posts.length > 0 &&
										<ul className="pagination">
											<li>
												<button disabled={!(this.state.page > 1)} onClick={() => this.pagination(-1)} className="prev btn"><i className="pe-7s-angle-left"></i></button>
											</li>
											{
												this.state.page > 3 ? 
													<><li><button onClick={() => this.pagination(-this.state.page + 1)}>1</button></li>
													<li> ... </li></> : null
											}
											{
												this.state.page > 1 ? <li><button onClick={() => this.pagination(-1)} >{this.state.page - 1}</button></li> : null
											}
											<li><button className="active">{this.state.page}</button></li>
											{
												this.state.page < this.state.total_pages ? <li><button onClick={() => this.pagination(1)} >{this.state.page + 1}</button></li> : null
											}
											{
												this.state.page + 2 < this.state.total_pages ? 
													<><li> ... </li>
													<li><button onClick={() => this.pagination(-(+this.state.page - +this.state.total_pages))}>{this.state.total_pages}</button></li></> : null
											}
											<li>
												<button disabled={!(this.state.page < this.state.total_pages)} onClick={() => this.pagination(1)} className="prev btn"><i className="pe-7s-angle-right"></i></button>
											</li>
										</ul>
									}
								</div>
							</div>
							<div className="col-md-4 col-sm-4 left-padding">
								{/* <SearchBox /> */}
								<AdBanner />
								<NewsBox />
								<CommentsLast />
								{/* <VideoPlayer /> */}
							</div>
						</div>
					</div>
				</section>
			</Fragment>
		)
	}
}


export default withRouter(DocumentsPage);
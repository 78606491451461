import React from 'react';
import ProjectSmallItems from './ProjectSmallItems';
import ProjectItems from './ProjectItems';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';

const options = {
  items: 1,
  responsive:{
    479:{
        items:1,
        nav:true
    },
    768:{
        items:1,
        nav:true
    },
    980:{
        items:1,
        nav:true
    },
    1199:{
        items:1,
        nav:true
    }
  },
  pagination: false,
  dots: false,
  lazyLoad: true,
  nav: true,
  navText: [
      "<i class='fa pe-7s-angle-left'></i>",
      "<i class='fa pe-7s-angle-right'></i>"
  ],
  //navElement: 'category-headding'
};

const size = 2;

const smallProjectsNumber = 4;

export default function Projects({projects}) {
  let bigProject = [];
  let smallProject = [];
  createTwoArray(projects, bigProject, smallProject);
  return (
    <section className="politics_wrapper">
      <h3 className="category-headding ">POSLEDNJI PROJEKTI</h3>
      <div className="headding-border"></div>
      <div className="row">
        {projects.length > 0 &&
          <OwlCarousel options={options} id="content-slide">
            {bigProject.slice(0, size).map((project, index) => {
                return (
                  <div key={project.id} className="item">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <ProjectItems item={project} />
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="row rn_block d-flex flex-wrap">
                          {smallProject.map((item, i) => {
                            if (i === i % smallProjectsNumber + index * smallProjectsNumber) {
                              return(
                                <ProjectSmallItems key={item.id} item={item}/>
                              )
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            )}
          </OwlCarousel>
        }
      </div>
    </section>
  )
}

const createTwoArray = (array, firstArray, secondArray) => {
  array.forEach((element, index) => {
    index % 5 === 0 && firstArray.push(element);
    index % 5 !== 0 && secondArray.push(element);
  });
}
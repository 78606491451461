import React from 'react';
import * as constants from '../constants';
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import stripHtml from "string-strip-html";
import TextTruncate from 'react-text-truncate';
import Truncate from 'react-truncate';
const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

export default function RecentNewsItem({item}) {
  return (
    <>
      <div className="post-wrapper wow fadeIn latest-news" data-wow-duration="1s">
        {/* <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`} style={{display: 'block'}}><TextTruncate
          line={1}
          element="h3"
          truncateText="…"
          text={stripHtml(item.title.rendered)}
        /></Link> */}
        <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}>
          <h3 className="line-clamp-one">
            {stripHtml(item.title.rendered)}
          </h3>
        </Link>
        <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}>
          <img className="img-custom-news" src={(item._embedded['wp:featuredmedia']) ? item._embedded['wp:featuredmedia']['0'].source_url : 'images/slider/slide-02.jpg'} alt={item._embedded['wp:featuredmedia'] ? item._embedded['wp:featuredmedia']['0'].title.rendered : 'image'} />
        </Link>
        {/* <div className="post-info meta-info-rn">
          <div className="slide">
            <Link to="/" className="post-badge btn_six">T</Link>
          </div>
        </div> */}
        <div className="post-title-author-details">
          <div className="post-editor-date">
            <div className="post-date">
              <i className="pe-7s-clock"></i> <Moment format="DD/MM/YYYY">{item.date}</Moment>
            </div>
            <div className="post-author-comment"><i className="pe-7s-comment">
              </i> {item._embedded.replies ? item._embedded.replies[0].length : 0} 
            </div>
          </div>
          {/* <TextTruncate
            line={3}
            element="p"
            truncateText="…"
            text={stripHtml(item.content.rendered)}
            textTruncateChild={<Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}>Pročitaj više</Link>}
          /> */}
          <p className="line-clamp-three">
            {stripHtml(item.content.rendered)}
          </p>
        </div>
      </div>
    </>
  )
}
import React from 'react';
import RecentNewsItem from './RecentNewsItem';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';

const size = 10;

const options = {
  items: 1,
  responsive:{
    479:{
        items:1,
        nav:true
    },
    600:{
      items:2,
      nav:true
  },
    768:{
        items:2,
        nav:true
    },
    980:{
        items:2,
        nav:true
    },
    1199:{
        items:2,
        nav:true
    }
  },
  pagination: false,
  dots: false,
  lazyLoad: true,
  nav: true,
  navText: [
      "<i class='fa pe-7s-angle-left'></i>",
      "<i class='fa pe-7s-angle-right'></i>"
  ],
  //navElement: 'category-headding'
};

export default function RecentNews({news}) {
  let oddProjects = [];
  let evenProjects = [];
  createTwoArray(news, oddProjects, evenProjects);
  return (
    <section className="recent_news_inner">
      <h3 className="category-headding ">POSLEDNJE VESTI</h3>
      <div className="headding-border"></div>
      <div className="row">
          {oddProjects.length > 0 &&
            <OwlCarousel options={options} id="content-slide">
            {oddProjects.slice(0, size).map((post, index) => {
              return (
                <div key={post.id} className="item">
                  <RecentNewsItem item={post} />
                  {evenProjects.map((item, i) => {
                    if (i === index){
                      return (
                          <RecentNewsItem key={item.id} item={item} />
                      )
                    }
                  })}
                </div>
              )})}
            </OwlCarousel>
          }
      </div>
    </section>
  )
}

const createTwoArray = (array, firstArray, secondArray) => {
  array.forEach((element, index) => {
    index % 2 === 0 && firstArray.push(element);
    index % 2 !== 0 && secondArray.push(element);
  });
}
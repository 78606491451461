import React from 'react';

export default function AdBanner(props) {
  return (
    <div className="banner-add">
      {/* <span className="add-title">- Reklama -</span> */}
      <a href="https://otvorenavratapravosudja.rs/" target="_blank"><img width="250px" height="250px" src="/images/Baner-2.jpg" className="img-responsive center-block" alt="" /></a>
    </div>
  )
}

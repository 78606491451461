import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: false
    }
  }

  showNav = () => {
    this.setState({ nav: !this.state.nav });
  }

  hideNav = () => {
    this.setState({ nav: false })
  }

  render() {
    return (
      <header>
        <div className="mobile-menu-area navbar-fixed-top hidden-sm hidden-md hidden-lg">
          <nav className={"mobile-menu " + ( this.state.nav ? "mobile-menu-open" : '' )} id="mobile-menu">
            <div className="sidebar-nav">
              <ul className="nav side-menu">
                <li className="sidebar-search">
                  {/* <div className="input-group custom-search-form">
                    <input type="text" className="form-control" placeholder="Search..." />
                    <span className="input-group-btn">
                      <button className="btn mobile-menu-btn" type="button">
                        <i className="fa fa-search"></i>
                      </button>
                    </span>
                  </div> */}
                </li>
                <li onClick={this.hideNav}><Link to="/">Početna</Link></li>
                <li onClick={this.hideNav}><Link to="/vesti">Vesti</Link></li>
                <li onClick={this.hideNav}><Link to="/projekti">Projekti</Link></li>
                <li onClick={this.hideNav}><Link to="/dokumenta">Dokumenta</Link></li>
                <li onClick={this.hideNav}><Link to="/galerija">Galerija</Link></li>
                <li onClick={this.hideNav}><Link to="/o-nama">O Nama</Link></li>
                <li onClick={this.hideNav}><Link to="/medijsko-skloniste">Medijsko sklonište</Link></li>
                <li>
                  <div className="social">
                    <ul>
                      <li><a href="https://www.facebook.com/respublica034/" className="facebook"><i className="fa  fa-facebook"></i> </a></li>
                      <li><a href="https://twitter.com/respublica034" className="twitter"><i className="fa  fa-twitter"></i></a></li>
                      {/* <li><a href="#" className="google"><i className="fa  fa-google-plus"></i></a></li> */}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
          <div className="container">
            <div className="top_header_icon">
              <span className="top_header_icon_wrap">
                <a target="_blank" href="https://twitter.com/respublica034" title="Twitter"><i className="fa fa-twitter"></i></a>
              </span>
              <span className="top_header_icon_wrap">
                <a target="_blank" href="https://www.facebook.com/respublica034/" title="Facebook"><i className="fa fa-facebook"></i></a>
              </span>
              {/* <span className="top_header_icon_wrap">
                <a target="_blank" href="#" title="Google"><i className="fa fa-google-plus"></i></a>
              </span>
              <span className="top_header_icon_wrap">
                <a target="_blank" href="#" title="Vimeo"><i className="fa fa-vimeo"></i></a>
              </span>
              <span className="top_header_icon_wrap">
                <a target="_blank" href="#" title="Pintereset"><i className="fa fa-pinterest-p"></i></a>
              </span> */}
            </div>
            <div id="showLeft" className={"nav-icon " + ( this.state.nav ? "open active" : "disabled" )} onClick={this.showNav}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div className="top_banner_wrap">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-4 col-sm-4">
                <div className="header-logo">
                  <Link to="/" onClick={this.hideNav}>
                    <img className="td-retina-data img-responsive" src="/images/respublica034_logo.png" alt="Res Publica" />
                  </Link>
                </div>
              </div>
              <div className="col-xs-8 col-md-8 col-sm-8 hidden-xs">
                <div className="header-banner">
                  <Link to="/" onClick={this.hideNav}><img className="td-retina custom-header-banner" src="/images/baner_nas.jpg" alt="baner" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container hidden-xs">
          <nav className="navbar">
            <div className="collapse navbar-collapse">
              <ul className="nav navbar-nav">
                <li><Link to="/" className="category01">POČETNA</Link></li>
                <li><Link to="/vesti" className="category07">VESTI</Link></li>
                <li><Link to="/projekti" className="category03">PROJEKTI</Link></li>
                <li><Link to="/dokumenta" className="category04">DOKUMENTA</Link></li>
                <li><Link to="/galerija" className="category05">GALERIJA</Link></li>
                <li><Link to="/o-nama" className="category06">O NAMA</Link></li>
                <li><Link to="/medijsko-skloniste" className="category02">MEDIJSKO SKLONIŠTE</Link></li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    )
  }
}

export default withRouter(Header);
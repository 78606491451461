import React from 'react';
import RecentNews from './RecentNews';
import Projects from './Projects';
import NewsBox from './NewsBox';
import CommentsLast from './CommentsLast';
import Ad from './Ad';
import SearchBox from './SearchBox';
import AdBanner from './AdBanner';
import VideoPlayer from './VideoPlayer';

export default function HomeContent({news, projects}) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-sm-8">
          <RecentNews news={news} />
          <Projects projects={projects}/>
          <Ad />
        </div>
        <div className="col-md-4 col-sm-4 left-padding">
          {/* <SearchBox /> */}
          <AdBanner />
          <NewsBox />
          <CommentsLast />
          <VideoPlayer />
        </div>
      </div>
    </div>
  )
}
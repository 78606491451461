import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import NewsBox from '../components/NewsBox';
import CommentsLast from '../components/CommentsLast';
import SearchBox from '../components/SearchBox';
import AdBanner from '../components/AdBanner';
import VideoPlayer from '../components/VideoPlayer';

class NotFound extends React.Component {

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Stranica ne postoji</title>
        </Helmet>
        <section className="article-post-inner">
          <div className="container mt-3">
            <div className="row">
              <div className="col-sm-8">
                <h3 className="mt-0">Stranica ne postoji.</h3>
                <p>Molimo vas da osvežite stranicu ili pokušajte da odete na neku drugu.</p>
                <Link to="/"><span className="btn btn-style">Nazad na početnu</span></Link>
              </div>
              <div className="col-md-4 col-sm-4 left-padding">
                {/* <SearchBox /> */}
                <AdBanner />
                <NewsBox />
                <CommentsLast />
                {/* <VideoPlayer /> */}
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

export default withRouter(NotFound);
import React from 'react';
import { getPosts } from '../api';
import NewsBoxItem from './NewsBoxItem';

export default class NewsBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    }
  }

  async componentDidMount() {
    try {
      const posts = await getPosts(false, 30);
      posts.sort((a,b) => {
        if (a._embedded.replies !== undefined && b._embedded.replies !== undefined) {
          return (a._embedded.replies[0].length > b._embedded.replies[0].length) ? -1 : 1;
        }
        if (a._embedded.replies === undefined && b._embedded.replies !== undefined) {
          return 1;
        }
        if (a._embedded.replies !== undefined && b._embedded.replies === undefined) {
          return -1;
        }
        return 1;
      });
			this.setState({ posts })
		} catch (error) {
			console.log('most popular', error);
		}
  }
  
  render() {
    return (
      <div className="tab-inner">
        <ul className="tabs">
          <li className="current news-box"><a>NAJPOPULARNIJE</a></li>
          {/* <li><a>NAJCITANIJE</a></li> */}
        </ul>
        <hr />
        <div className="tab_content">
          <div className="tab-item-inner">
            {this.state.posts.length > 0 && 
              this.state.posts.slice(0,4).map(post => <NewsBoxItem key={post.id} item={post} />)
            }
          </div>
        </div>
      </div>
    )    
  }
}
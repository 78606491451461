import React from 'react';
import * as constants from '../constants';
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import stripHtml from "string-strip-html";
import TextTruncate from 'react-text-truncate';
import Truncate from 'react-truncate';
const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

export default function ArticlesItem({item}) {
  return (
    <div className="col-xs-12 col-md-4 col-sm-4 post-padding">
      <div className="post-wrapper wow fadeIn mb-1" data-wow-duration="1s">
        <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}>
          <img className="img-custom-articles" src={item._embedded['wp:featuredmedia'] ? item._embedded['wp:featuredmedia']['0'].source_url : '/images/articale.jpg'} alt={item._embedded['wp:featuredmedia'] ? item._embedded['wp:featuredmedia']['0'].title.rendered : 'image'} />
        </Link>
        {/* <div className="post-info meta-info-rn">
          <div className="slide">
            <Link to={`/posts/${item.id}`} className="post-badge btn_five">B</Link>
          </div>
        </div> */}
      </div>
      <div className="post-title-author-details"> 
        {/* <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}>
          <h4>{stripHtml(item.title.rendered)}</h4>
        </Link> */}
        {/* <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}><TextTruncate
					line={1}
					element="h4"
					truncateText="…"
					text={stripHtml(item.title.rendered)}
					/></Link>	 */}
        <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}>
          <h4 className="line-clamp-one">
            {stripHtml(item.title.rendered)}
          </h4>
        </Link>
        <div className="post-editor-date mt-1">
          <div className="post-date">
            <i className="pe-7s-clock"></i> <Moment format="DD/MM/YYYY">{item.date}</Moment>
          </div>
          <div className="post-author-comment"><i className="pe-7s-comment"></i> {item._embedded.replies ? item._embedded.replies[0].length : 0} </div>
        </div>
      </div>
    </div>
  )
}
import React from 'react';
import Articles from './Articles';
import VideoPlayer from './VideoPlayer';

export default function HomeBottom({documents}) {
  return (
    <div className="lat_arti_cont_wrap">
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <Articles documents={documents} />
          </div>
          <div className="col-md-4 col-sm-4 left-padding">
            {/* <VideoPlayer /> */}
          </div>
        </div>
      </div>
    </div>
  )
}
import React, { Fragment } from 'react';
import { withRouter } from 'react-router'
import NewsBox from '../components/NewsBox';
import CommentsLast from '../components/CommentsLast';
import SearchBox from '../components/SearchBox';
import AdBanner from '../components/AdBanner';
import VideoPlayer from '../components/VideoPlayer';

class InProgressPage extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Fragment>
        <section className="article-post-inner">
          <div className="container mt-3">
            <div className="row">
              <div className="col-sm-8">
                <h2 className="mt-0">Poštovani, strana je u izradi. Hvala na razumevanju.</h2>
              </div>
              <div className="col-md-4 col-sm-4 left-padding">
                {/* <SearchBox /> */}
                <AdBanner />
                <NewsBox />
                <CommentsLast />
                {/* <VideoPlayer /> */}
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

export default withRouter(InProgressPage);
import React from 'react';

export default function Footer(props) {
  return (
    <div className="sub-footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <p>Copyright &copy; {new Date().getFullYear()} All Rights Reserved | Šumadijski centar za građanski aktivizam "RES PUBLICA"</p>
            <div className="social">
              <ul>
                <li><a href="https://www.facebook.com/respublica034/" target="_blank" className="facebook"><i className="fa  fa-facebook"></i> </a></li>
                <li><a href="https://twitter.com/respublica034" target="_blank" className="twitter"><i className="fa  fa-twitter"></i></a></li>
                {/* <li><a href="#" className="google"><i className="fa  fa-google-plus"></i></a></li> */}
                {/* <li><a href="#" className="flickr"><i className="fa fa-flickr"></i></a></li> */}
                <li><a href="https://www.youtube.com/channel/UC_iCa7cIEpI6Rg7U301Qcqg" target="_blank" className="youtube"><i className="fa fa-youtube"></i></a></li>
                {/* <li><a href="#" className="vimeo"><i className="fa fa-vimeo"></i></a></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import axios from 'axios';

const apiUrl = 'http://wp.respublica034.rs/wp-json/wp/v2';

axios.defaults.headers['Content-Type'] = 'application/json';

const options = {
  headers: {'Content-Type': 'application/json'},
  responseEncoding: 'utf8',
};

export async function getPosts(sticky = false, per_page = 30) {
  try {
    const response = await axios.get(apiUrl + '/posts?per_page=' + per_page + '&sticky=' + sticky + '&_embed', options);
    return response.data
  } catch (error) {
    throw error;
  }
}

export async function getCategory(categoryId) {
  try {
    const response = await axios.get(apiUrl + '/posts?categories=' + categoryId + '&_embed', options);
    return response.data
  } catch (error) {
    throw error;
  }
}

export async function getCategoryPagination(categoryId, offset = 1, per_page = 5) {
  try {
    const response = await axios.get(apiUrl + '/posts?categories=' + categoryId +'&offset=' + offset + '&per_page=' + per_page + '&_embed', options);
    return response
  } catch (error) {
    throw error;
  }
}

export async function getPost(slug) {
  try {
    const response = await axios.get(apiUrl + '/posts?slug=' + slug + '&_embed', options);
    return response.data
  } catch (error) {
    throw error;
  }
}

export async function getPostById(id) {
  try {
    const response = await axios.get(apiUrl + '/posts/' + id, options);
    return response.data
  } catch (error) {
    throw error;
  }
}

export async function createComment(authorName, authorEmail, content, postId, parent = 0) {
  try {
    const response = await axios.post(apiUrl + '/comments?' + 'content=' + content + '&author_name=' + authorName + '&author_email=' + authorEmail + '&post=' + postId + '&parent=' + parent, options);
    return response.data
  } catch (error) {
    throw error;
  }
}

export async function getLastComments(per_page = 30) {
  try {
    const response = await axios.get(apiUrl + '/comments?per_page=' + per_page + '&_embed', options);
    return response.data
  } catch (error) {
    throw error;
  }
}
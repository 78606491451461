import React from 'react';
import * as constants from '../constants';
import { withRouter } from 'react-router'
import Moment from 'react-moment';
import stripHtml from "string-strip-html";
import TextTruncate from 'react-text-truncate';
import { getPostById } from '../api';
const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

class SingleComment extends React.Component {

  test = async id => {
    try {
      const post = await getPostById(id);
      this.redirectTo(`/${constants.data.routes[post.categories[0]]}/${post.slug}`);
    } catch (error) {
      console.log('error comment', error);
    }
  }

  redirectTo = route => this.props.history.push(route);

  render() {
    const {item} = this.props; 
    return (
      <div className="post-style2 latest-com cursor-pointer" onClick={() => this.test(item.post)}>
        <img src="/images/user.png" alt="user" />
        <div className="latest-com-detail w-100">
          <p>
            Autor: {item.author_name ? item.author_name : ''}
          </p>
          <p className="single-comment">Postavljeno: <Moment format="DD/MM/YYYY">{item.date ? item.data : ''}</Moment></p>
          {/* <TextTruncate
            line={2}
            truncateText="…"
            text={stripHtml(item.content.rendered)}
          /> */}
          <div className="line-clamp-two">
            <span className="mb-0">
              {stripHtml(item.content.rendered)}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(SingleComment);
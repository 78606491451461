import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router'
import NewsBox from '../components/NewsBox';
import CommentsLast from '../components/CommentsLast';
import SearchBox from '../components/SearchBox';
import AdBanner from '../components/AdBanner';
import VideoPlayer from '../components/VideoPlayer';

class AboutUsPage extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>O NAMA - Šumadijski centar za građanski aktivizam "RES PUBLICA"</title>
          <meta name="title" content='O NAMA - Šumadijski centar za građanski aktivizam "RES PUBLICA"' />
          <meta name="description" content="Šumadijski centar za građanski aktivizam „Res publika“ osnovan je decembra 2014. godine sa idejom da afirmiše demokratske vrednosti i principe, da informiše o građanskim i političkim pravima i njihovoj zaštiti, promoviše medijske slobode i pismenost, etičke i profesionalne standarde u izveštavanju i javnom govoru i pomogne stručno usavršavanje medijskih profesionalaca, kao i jačanje civilnog sektora." />
          <meta name="keywords" content="O nama, RES PUBLICA" />
          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          {/* <meta property="og:url" content="https://metatags.io/" /> */}
          <meta property="og:title" content='O NAMA - Šumadijski centar za građanski aktivizam "RES PUBLICA"' />
          <meta property="og:description" content="Šumadijski centar za građanski aktivizam „Res publika“ osnovan je decembra 2014. godine sa idejom da afirmiše demokratske vrednosti i principe, da informiše o građanskim i političkim pravima i njihovoj zaštiti, promoviše medijske slobode i pismenost, etičke i profesionalne standarde u izveštavanju i javnom govoru i pomogne stručno usavršavanje medijskih profesionalaca, kao i jačanje civilnog sektora." />
          {/* <meta property="og:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png" /> */}

          {/* Twitter */}
          <meta property="twitter:card" content="summary_large_image" />
          {/* <meta property="twitter:url" content="https://metatags.io/" /> */}
          <meta property="twitter:title" content='O NAMA - Šumadijski centar za građanski aktivizam "RES PUBLICA"' />
          <meta property="twitter:description" content="Šumadijski centar za građanski aktivizam „Res publika“ osnovan je decembra 2014. godine sa idejom da afirmiše demokratske vrednosti i principe, da informiše o građanskim i političkim pravima i njihovoj zaštiti, promoviše medijske slobode i pismenost, etičke i profesionalne standarde u izveštavanju i javnom govoru i pomogne stručno usavršavanje medijskih profesionalaca, kao i jačanje civilnog sektora." />
          {/* <meta property="twitter:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png" /> */}
        </Helmet>
        <section className="article-post-inner">
          <div className="container mt-3">
            <div className="row">
              <div className="col-sm-8">
                <div className="post-thumb">
                  <img className="img-custom-entry-thumb" src="/images/Logo_600x240.jpg" alt="Res Publica" />
                </div>
                <p>
                  Šumadijski centar za građanski aktivizam „Res publika“ osnovan je decembra 2014. godine sa idejom da afirmiše demokratske vrednosti i principe, da informiše o građanskim i političkim pravima i njihovoj zaštiti, promoviše medijske slobode i pismenost, etičke i profesionalne standarde u izveštavanju i javnom govoru i pomogne stručno usavršavanje medijskih profesionalaca, kao i jačanje civilnog sektora. 
                </p>
                <p>
                  Suočena sa tendencijom gušenja slobode izražavanja, rastućim pritiscima na nezavisno novinarstvo i stalnim sužavanjem prostora za društveni dijalog o najvažnijim pitanjima, grupa novinara i drugih medijskih radnika prepoznala je potrebu da se angažuje i podstakne druge na građanski aktivizam. Kao dugogodišnji medijski profesionalci, posrednici između zvaničnika i građana, dobro upoznati sa javnom sferom i odnosima među akterima, osnivači udruženja žele da doprinesu promenama i osnaživanju društva za akcije i rešenja za koja su donosioci odluka nedovoljno zainteresovani ili bez dovoljno kapaciteta da ih sprovedu.
                </p>
                <p>
                  Osnivači i zakonski zastupnici udruženja Res Publika su Bojana Vlajović Savić i Marija Milošević.
                </p>
                <p>
                  <strong>Misija Res Publike</strong> je  kreiranje stimulativnog okruženja za funkcionisanje nezavisnih i slobodnih medija.
                </p>
                <p>
                  <strong>Vizija Res Publike</strong> je da je Kragujevac grad aktivnih gađana, medijskog pluralizma i demokratskih sloboda. 
                </p>
                <p>
                  Principi i vrednosti na kojima Res Publika temelji svoj rad i koje dele svi njeni članovi su:
                </p>
                <p>
                  <strong>Integritet:</strong> osnivači i članovi Res Publike postupaju i deluju u skladu sa ličnim moralnim načelima i profesionalnim standardima bez obzira na okolnosti i bez izuzetaka. To je baza na kojoj gradimo ceo sistem vrednosti, osnova na kojoj se zasnivaju poverenje i međuljudski odnosi u našoj organizaciji i celoj zajednici.
                </p>  
                <p>
                  <strong>Sloboda:</strong> pravo i moć svakog od nas da iskaže svoje mišljenje i stavove, da donosi odluke i živi u skladu sa svojim uverenjima… ograničeno tim istim pravom drugih.
                </p>
                <p>
                  <strong>Transparentnost:</strong> Res Publika je otvorena organizacija, spremna da sve informacije i aktivnosti učini javno dostupnim. Smatramo da poštovanjem ovog principa, kao jedne od ključnih organizacionih vrednosti, postižemo kvalitet i održivost rada, bolju kontrolu i povećavamo odgovornost.
                </p>
                <p>
                  <strong>Odgovornost:</strong> znači da smo spremni da preduzmemo inicijativu i pokrenemo promene, da “polažemo račune”, da snosimo posledice za sve aktivnosti, dela, reči, ponašanja…
                </p>
                <p>
                  <strong>Participativnost:</strong> Res Publika i njeni članovi spremni su da ostvare svoje pravo i ispune obavezu da se uključe u procese i promene u organizaciji i zajednici. Učestvujemo u donošenju i sprovođenju odluka koje se tiču svih nas, imamo izbor i moć da kontrolišemo, delegiramo i da gradimo partnerstva.
                </p>
                <p>
                  <strong>Ravnopravnost:</strong> Članovi Res Publike, kao i svi građani u lokalnoj zajednici su jednaki. Imamo jednak pristup resursima i jednake mogućnosti da učestvijemo, odlučujemo i delujemo, bez obzira na razlike koje mogu postojati na osnovu nekog ličnog svojstva, pripadnosti ili uverenja.
                </p>
              </div>
              <div className="col-md-4 col-sm-4 left-padding">
                {/* <SearchBox /> */}
                <AdBanner />
                <NewsBox />
                <CommentsLast />
                {/* <VideoPlayer /> */}
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

export default withRouter(AboutUsPage);
import React from 'react';
import * as constants from '../constants';
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import stripHtml from "string-strip-html";
import TextTruncate from 'react-text-truncate';
const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

export default function ProjectSmallItems({item}) {
	return (
		<div className="col-xs-6 col-md-6 col-sm-6 post-padding">
			<div className="post-thumb wow fadeIn" data-wow-duration="1s" data-wow-delay="0.1s">
				<Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}>
					<img className="img-custom-project-small" src={item._embedded['wp:featuredmedia'] ? item._embedded['wp:featuredmedia']['0'].source_url : 'images/slider/slide-02.jpg'} alt={item._embedded['wp:featuredmedia'] ? item._embedded['wp:featuredmedia']['0'].title.rendered : 'image'} />
				</Link>
			</div>
			<div className="post-title-author-details">
				{/* <Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}><TextTruncate
					line={1}
					element="h5"
					truncateText="…"
					text={stripHtml(item.title.rendered)}
					/></Link>		 */}
				<Link to={`/${constants.data.routes[item.categories[0]]}/${item.slug}`}>
          <h5 className="line-clamp-one">
            {stripHtml(item.title.rendered)}
          </h5>
        </Link>
				<div className="post-editor-date">
					<div className="post-date">
						<i className="pe-7s-clock"></i> <Moment format="DD/MM/YYYY">{item.date}</Moment>
					</div>
					<div className="post-author-comment"><i className="pe-7s-comment"></i> {item._embedded.replies ? item._embedded.replies[0].length : 0} </div>
				</div>
			</div>
		</div>
	)
}
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router'
import * as constants from '../constants';
import NewsBox from '../components/NewsBox';
import CommentsLast from '../components/CommentsLast';
import SearchBox from '../components/SearchBox';
import AdBanner from '../components/AdBanner';
import VideoPlayer from '../components/VideoPlayer';
import ArticlesItem from '../components/ArticlesItem';
import Comment from '../components/Comment';
import { getPost, createComment, getPosts, getCategory } from '../api';
import OwlCarousel from "react-owl-carousel2";
import Moment from 'react-moment';
const Entities = require('html-entities').XmlEntities;
const entities = new Entities();

const options = {
  items: 1,
  responsive:{
    479:{
      items:1,
      nav:true
    },
    768:{
      items:1,
      nav:true
    },
    980:{
      items:1,
      nav:true
    },
    1199:{
      items:1,
      nav:true
    }
  },
  pagination: false,
  dots: false,
  lazyLoad: true,
  nav: true,
  navText: [
    "<i class='fa pe-7s-angle-left'></i>",
    "<i class='fa pe-7s-angle-right'></i>"
  ],
  //navElement: 'category-headding'
};

const sizeHeadNews = 2;
const sizeRelated = 3;

class PostsPage extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
			post: {},
			posts: [],
			related: [],
			name: '',
			email: '',
			message: '',
			comments: [],
			commentResponse: [],
			commentMessage: '',
			showSpinner: true,
    }
	}
	
	async componentDidMount() {
		window.scrollTo(0, 0)
		let slug = window.location.pathname.split('/')[2]
		try {
			const postsBySlug = await getPost(slug);
			const post = postsBySlug[0];
			const posts = await getPosts();
			const related = await getCategory(post.categories[0]);
			const relatedFiltered = related.filter(item => item.id !== post.id);
			const comments = post._embedded.replies ? post._embedded.replies[0] : [];
			this.setState({ post, posts, related: relatedFiltered, comments, showSpinner: false });
		} catch (error) {
			this.redirectTo('/');
		}
	}

	async componentWillReceiveProps(nextProps){
		if (nextProps.location.pathname !== this.props.location.pathname) {
			this.setState({ showSpinner: true })
			let slug = nextProps.location.pathname.split('/')[2]
			try {
				const postsBySlug = await getPost(slug);
				const post = postsBySlug[0];
				const posts = await getPosts();
				const related = await getCategory(post.categories[0])
				const relatedFiltered = related.filter(item => item.id !== post.id);
				const comments = post._embedded.replies ? post._embedded.replies[0] : [];
				this.setState({ post, posts, related: relatedFiltered, comments, showSpinner: false });
				window.scrollTo(0, 0)
			} catch (error) {
				this.redirectTo('/');
			}
		}
  }

	handleOnChange = (inputName, event) => this.setState({ [inputName]: event.target.value});

	redirectTo = route => this.props.history.push(route);

	submit = async () => {
		try {
			const commentResponse = await createComment(this.state.name, this.state.email, this.state.message, this.state.post.id);
			this.setState({ commentResponse, name: '', email: '', message: '', commentMessage: constants.successComment });
		} catch (error) {
			if (+error.response.status === 409) {
				this.setState({ commentMessage: constants.duplicateComment });
			}
		}
	}

	render() {
		const emailChekc = this.state.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
		return (
			<Fragment>
				<Helmet>
					<title>{this.state.post.title ? `${entities.decode(this.state.post.title.rendered)} - ` : ''}Šumadijski centar za građanski aktivizam "RES PUBLICA"</title>
          <meta name="title" content={this.state.post.title ? `${entities.decode(this.state.post.title.rendered)} - Šumadijski centar za građanski aktivizam "RES PUBLICA"` : ''} />
          <meta name="description" content={this.state.post.title ? `${entities.decode(this.state.post.title.rendered)}` : ''} />
					<meta name="keywords" content="Dokument, RES PUBLICA" />
          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          {/* <meta property="og:url" content="https://metatags.io/" /> */}
          <meta property="og:title" content={this.state.post.title ? `${entities.decode(this.state.post.title.rendered)} - Šumadijski centar za građanski aktivizam "RES PUBLICA"` : ''} />
          <meta property="og:description" content={this.state.post.title ? `${entities.decode(this.state.post.title.rendered)}` : ''} />
          {/* <meta property="og:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png" /> */}

          {/* Twitter */}
          <meta property="twitter:card" content="summary_large_image" />
          {/* <meta property="twitter:url" content="https://metatags.io/" /> */}
          <meta property="twitter:title" content={this.state.post.title ? `${entities.decode(this.state.post.title.rendered)} - Šumadijski centar za građanski aktivizam "RES PUBLICA"` : ''} />
          <meta property="twitter:description" content={this.state.post.title ? `${entities.decode(this.state.post.title.rendered)} - ` : ''} />
          {/* <meta property="twitter:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png" /> */}
				</Helmet>
				{this.state.showSpinner ? <div className="se-pre-con"></div> : ''}
				<div className="container mt-3">
					<div className="row">
						<div className="col-md-8 col-sm-8">
							{this.state.post._embedded !== undefined && 
								<article>	
									<div className="post-thumb">
										<img className="img-custom-entry-thumb" src={this.state.post._embedded['wp:featuredmedia'] ? this.state.post._embedded['wp:featuredmedia']['0'].source_url : '/images/slider/slide-02.jpg'} alt={this.state.post._embedded['wp:featuredmedia'] ? this.state.post._embedded['wp:featuredmedia']['0'].title.rendered : 'image'} />
										{/* Social */}
										{/* <div className="social">
											<ul>
												<li><a href="#" className="facebook"><i className="fa  fa-facebook"></i><span>3987</span> </a></li>
												<li><a href="#" className="twitter"><i className="fa  fa-twitter"></i><span>3987</span></a></li>
												<li><a href="#" className="google"><i className="fa  fa-google-plus"></i><span>3987</span></a></li>
												<li><a href="#" className="flickr"><i className="fa fa-flickr"></i><span>3987</span> </a></li>
											</ul>
										</div> */}
									</div>
									<h1>{entities.decode(this.state.post.title.rendered)}</h1>
									<div className="date">
										<ul>
											<li>Autor <span className="custom-comments">{this.state.post._embedded.author ? this.state.post._embedded.author[0].name : ''}</span> --</li>
											<li><Moment format="DD/MM/YYYY">{this.state.post.date ? this.state.post.date : ''}</Moment> --</li>
											<li><span className="custom-comments">Komentari ({this.state.post._embedded.replies ? this.state.post._embedded.replies[0].length : 0})</span></li>
										</ul>
									</div>
									{/* {this.state.post.content.rendered} */}
                  <span dangerouslySetInnerHTML={{__html: this.state.post.content.rendered}}></span>
									{/* Quote */}
									{/* <div className="qtrotator">
										<div className="qtcontent">
											<blockquote>
													here are many variations of passages of Lorem Ipsum available, but the majority <a href="#">have suffered alteration</a> in some form
											</blockquote>
										</div>
									</div> */}
									{/* Tags */}
									{/* <div className="tags">
										<ul>
											<li> <a href="#">Education</a></li>
											<li> <a href="#">Health &amp; Fitness</a></li>
											<li><a href="#">Fashion</a></li>
											<li><a href="#">Collage</a></li>
											<li><a href="#">Business</a></li>
											<li><a href="#">Music</a></li>
											<li><a href="#">Blog</a></li>
											<li><a href="#">Lifestyle</a></li>
										</ul>
									</div> */}
									{/* Related news area ============================================  */}
									<div className="related-news-inner">
										<h3 className="category-headding ">Iz iste kategorije</h3>
										<div className="headding-border"></div>
										<div className="row">
										{this.state.related.length > 0 &&
											<OwlCarousel options={options} id="content-slide">
												{this.state.related.slice(0, 2).map((related, index) => {
													return (
														<div key={index} className="item">
															<div className="row rn_block">
																{this.state.related.map((item, i) => {
																	if (i === i % sizeRelated + index * sizeRelated) {
																		return(
																			<ArticlesItem key={item.id} item={item}/>
																		)
																	}
																})}
															</div>
														</div>
													)
												})}
											</OwlCarousel>
										}
										</div>
									</div>
									{/* form ============================================ */}
									{this.state.post.comment_status === 'open' &&
										<div className="form-area">
											<h3 className="category-headding ">Ostavite Vaš komentar</h3>
											<div className="headding-border"></div>
											{this.state.commentMessage ? <p>{this.state.commentMessage}</p> : ''}
											<form>
												<div className="row">
													<div className="col-sm-6">
														<span className={"input " + (this.state.name !== '' ? "input--filled" : '')}>
															<input 
																className="input_field" 
																type="text" 
																onChange={this.handleOnChange.bind(this, 'name')}
																value={this.state.name} />
															<label className="input_label" htmlFor="input-1">
																<span className="input_label_content" data-content="Your Name">Vaše ime</span>
															</label>
														</span>
													</div>
													<div className="col-sm-6">
														<span className={"input " + (this.state.email !== '' ? "input--filled" : '')}>
															<input 
																className="input_field" 
																type="email" 
																onChange={this.handleOnChange.bind(this, 'email')}
																value={this.state.email}/>
															<label className="input_label" htmlFor="input-2">
																<span className="input_label_content" data-content="Your Email">Vaš email</span>
															</label>
														</span>
													</div>
													<div className="col-sm-12">
														<span className={"input " + (this.state.message !== '' ? "input--filled" : '')}>
															<textarea 
																className="input_field" 
																onChange={this.handleOnChange.bind(this, 'message')}
																value={this.state.message}>
															</textarea>
															<label className="input_label" htmlFor="message">
																	<span className="input_label_content" data-content="Your Message">Vaša poruka</span>
															</label>
														</span>
														<button type="button" onClick={this.submit} disabled={!this.state.name || !emailChekc || !this.state.message} className="btn btn-style" style={{ outline: '0'}}>Postavite Vaš komentar</button>
													</div>
												</div>
											</form>
										</div>
									}
									{/* comment box ============================================  */}
									{this.state.post.comment_status === "open" && 
										<div className="comments-container">
											<h1>Komentari </h1>
											{this.state.comments.length > 0 &&
											<ul id="comments-list" className="comments-list">
												{this.state.comments.map(comment => {
													if (comment.parent === 0) {
														let subComment;
														const comments = this.state.comments.map(item => {
															if (item.parent === comment.id) {
																return (<li key={item.id}><Comment postId={this.state.post.id} item={item} reply={false} /></li>)
															}
														}).filter(item => item !== undefined)
														if (comments.length > 0 ) {
															subComment = (<ul id="comments-list" className="comments-list reply-list">{comments}</ul>);
														} else {
															subComment = null;
														}
														return (
															<li key={comment.id}>
																<div className="comment-main-level">
																	<Comment item={comment} postId={this.state.post.id} reply={true} />
																</div>
																{subComment}
															</li>
														)
													}
												})}
											</ul>
											}
											{!(this.state.comments.length > 0) && 
												<p>Nema komentara na izabrani post.</p>
											}
										</div>
									}
								</article>
							}
						</div>
						<div className="col-md-4 col-sm-4 left-padding">
							{/* <SearchBox /> */}
							<AdBanner />
							<NewsBox />
							<CommentsLast />
							{/* <VideoPlayer /> */}
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}


export default withRouter(PostsPage);

// const nestedComments = (array, id, postId) => {
// 	const comments = array.map(item => {
// 		console.log('komentari', id, item.parent)
// 		if (item.parent === id) {
// 			return (<li key={item.id}><Comment postId={postId} item={item} reply={false} /></li>)
// 		}
// 	}).filter(item => item !== undefined)
// 	if (comments.length > 0 ) {
// 		return (<ul id="comments-list" className="comments-list reply-list">{comments}</ul>)
// 	}
// }
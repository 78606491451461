export const categoryNewsNumber = 50;
export const categoryProjectsNumber = 51;
export const categoryDocumentsNumber = 52;
export const categoryMediaShelterNumber = 53;
export const duplicateComment = "Molimo Vas promenite komentar pošto postoji identičan. Hvala!";
export const successComment = "Vaš komentar čeka odobrenje. Hvala na komentarisanju!";

export const data = {
  "category": {
    "50": "Vesti",
    "51": "Projekti",
    "52": "Dokumenta",
    "53": "Medijsko Sklonište"
  },
  "routes": {
    "50": "vesti",
    "51": "projekti",
    "52": "dokumenta",
    "53": "medijsko-skloniste"
  }
}